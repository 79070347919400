import {KTIcon} from '../../../../_metronic/helpers'
const AddNewReportModalHeader = ({
  setOpenNewReportModal,
  selectedReport,
  setSelectedReport,
  removeReport
}) => {  
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      {selectedReport && selectedReport?.job_id && <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={removeReport}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='trash' className='fs-1' />
      </div>
       }
      <h2 className='fw-bolder'>{selectedReport && selectedReport?.job_id ? `Edit Scheduler Report` : "Add New Scheduler Report" }</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
        setOpenNewReportModal(false)
        setSelectedReport(null)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AddNewReportModalHeader}
