import {useEffect} from 'react'
import {AddNewReportModalHeader} from './AddNewReportModalHeader'
import {AddNewReportModalFormWrapper} from './AddNewReportModalFormWrapper'
import axios from "axios";
import { toast } from "react-toastify";
import { ToastStyle } from "../../../../_metronic/helpers/utils";

const AddNewReportModal = ({
  setOpenNewReportModal,
  getScheduleReports,
  selectedReport,
  setSelectedReport,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])


  const removeReport = () => {
    axios
    .delete(
      `${process.env.REACT_APP_API_V2_URL}/reports/excel/${selectedReport?.job_id}`
    )
    .then((res) => {
        setOpenNewReportModal(false);
        getScheduleReports();
        toast("Report Deleted Successfully", ToastStyle);
    })
    .catch((err) => {
      if(err && err.response && err.response?.data && err.response?.data?.message){
        toast(err.response.data.message, ToastStyle);
      }else{
        toast("Technical Error", ToastStyle);
      }
    });
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <AddNewReportModalHeader setOpenNewReportModal={setOpenNewReportModal} selectedReport={selectedReport} setSelectedReport={setSelectedReport} removeReport={removeReport} />
            {/* begin::Modal body */}
            <div className={`modal-body scroll-y mx-5 mx-xl-15 my-7 `}>
              <AddNewReportModalFormWrapper setOpenNewReportModal={setOpenNewReportModal} getScheduleReports={getScheduleReports} selectedReport={selectedReport} setSelectedReport={setSelectedReport} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {AddNewReportModal}
