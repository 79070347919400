import { useState, useEffect } from "react";
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import "./style.css";
import { AddNewReportModal } from "./ReportModal/AddNewReportModal";
import moment from "moment";
import { ScheduleReportsHeader } from "./header/ScheduleReportsHeader"; 
import { ListingModal } from "./ListingModal/ListingModal"
import { NotifierEmailModal } from "./NotifiersEmail/NotifierEmailModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { daysOfWeek } from "../../../_metronic/helpers/utils";


const ScheduleReports = () => {
  const [reports, setReports] = useState([])
  const [selectedReport, setSelectedReport] = useState(null);
  const [openNewReportModal, setOpenNewReportModal] = useState(false);
  const [openListingModal, setOpenListingModal] = useState(false);
  const [openNotifierEmailModal, setOpenNotifierEmailModal] = useState(false);

  const getScheduleReports = () => {
    axios.get(`${process.env.REACT_APP_API_V2_URL}/reports/excel`)
      .then((res) => {
        if (res.data) {
          const data = res.data.map((item) => {
            return {
              ...item,
              emails: item.emails.split(","),
              listings: Object?.keys(item?.listings)?.map(id => ({
                id: +id,
                name: item.listings[id]
              }))
            }
          })
          console.log(data)
          setReports(data)
        }
      }).catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  }

  useEffect(() => {
    getScheduleReports()
  }, [])

  return (
    <KTCard>
      <ScheduleReportsHeader setOpenNewReportModal={setOpenNewReportModal}/>
      <KTCardBody>
        <div className="row g-4">
          {reports && reports.length > 0 ? (
            <div
              className="mb-10 mt-5 rest-stop-container"
            >
              {reports.map((item, index) => (
                <div
                  className="form-check form-check-custom form-check-solid d-flex justify-content-between align-items-center flex-wrap"
                  key={index}
                  style={{
                    background: "#e3feee9e",
                    padding: "10px",
                    borderRadius: "5px"
                  }}
                >
                  <div className="fv-row w-50">
                    <label className="fw-bold fs-6 mb-2">Created At</label>
                    <h5 className="text-main fw-bolder">{moment(item.createdAt).format("YYYY-MM-DD")}</h5>
                  </div>
                  <div className="fv-row w-50" style={{ textAlign: "right" }}>
                    <label className="fw-bold fs-6 mb-2">Status</label>
                    <h5 className={`${item.is_active ? "text-success" : "text-danger"} fw-bolder`}>
                      {item.is_active ? "Active" : "Inactive"}
                    </h5>
                  </div>
                  <div className="fv-row w-50">
                    <label className="fw-bold fs-6 mb-2">Day of the {item.cycle === 1 ? "Week" : "Month"}</label>
                    <h5 className="text-main fw-bolder">
                      {item.cycle === 1 ? daysOfWeek[+item.day]?.name || "Sunday" : item.day}
                    </h5>
                  </div>
                  <div className="fv-row w-50" style={{ textAlign: "right" }}>
                    <label className="fw-bold fs-6 mb-2">Cycle</label>
                    <h5 className="text-main fw-bolder">
                      {item.cycle === 1 ? "Weekly" : "Monthly"}
                    </h5>
                  </div>
                  <div className="fv-row w-100">
                    <label className="fw-bold fs-6 mb-2">Listing Name</label>
                    {
                      item?.listings && item.listings?.length ? (
                        item.listings.length < 2 ? (
                          <h5
                            className="text-main fw-bolder no-wrap break-word"
                          >
                              <Link
                                key={index}
                                to={`/listings/${item?.listings[0]?.id}`}
                                className="text-main fw-bolder"
                              >
                                {item?.listings[0]?.name || "-"}
                              </Link>
                          </h5>
                        ) : (
                          <h5>
                            <button
                              className="border-0 text-white"
                              style={{
                                backgroundColor: "rgb(255, 164, 89)",
                                borderRadius: "2px",
                                padding: "4px",
                              }}
                              onClick={() => {
                                setSelectedReport(item);
                                setOpenListingModal(true);
                              }}
                            >
                              Show All Listings
                            </button>
                          </h5>
                        )
                      ) : (
                        <h5 className="text-danger">-</h5>
                      )
                    }
                  </div>
                  <div className="fv-row w-100">
                    <label className="fw-bold fs-6 mb-2">Notifier Emails</label>
                    {
                      item?.emails && item.emails?.length ? (
                        item.emails.length < 2 ? (
                          <h5
                           className="text-main fw-bolder no-wrap break-word"
                          >
                              <span key={index}>{item?.emails[0] || "-"}</span>
                          </h5>
                        ) : (
                          <h5>
                            <button
                              className="border-0 text-white"
                              style={{
                                backgroundColor: "rgb(255, 164, 89)",
                                borderRadius: "2px",
                                padding: "4px",
                              }}
                              onClick={() => {
                                setSelectedReport(item);
                                setOpenNotifierEmailModal(true);
                              }}
                            >
                              Show All Notifier Emails
                            </button>
                          </h5>
                        )
                      ) : (
                        <h5 className="text-danger">-</h5>
                      )
                    }
                  </div>
                  <div className="fv-row w-50">
                    <label className="fw-bold fs-6 mb-2">Created By</label>
                    <h5 className="text-main fw-bolder">{item.created_by_admin}</h5>
                  </div>
                  <div className="fv-row w-50 d-flex justify-content-end">
                    <button
                      className="listing-remove"
                      onClick={() => {
                        setSelectedReport(item);
                        setOpenNewReportModal(true);
                      }}
                    >
                      <KTIcon iconName="pencil" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex text-center w-100 align-content-center justify-content-center">
              No Scheduler Found
            </div>
          )}
        </div>
      </KTCardBody>
      {openNewReportModal && (
        <AddNewReportModal
          openNewReportModal={openNewReportModal}
          setOpenNewReportModal={setOpenNewReportModal}
          getScheduleReports={getScheduleReports}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      )}
      {openListingModal && (
        <ListingModal
          setOpenListingModal={setOpenListingModal}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      )}
       {openNotifierEmailModal && (
        <NotifierEmailModal
          setOpenNotifierEmailModal={setOpenNotifierEmailModal}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      )}
    </KTCard>
  );
};

export default ScheduleReports;
