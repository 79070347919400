import css from './breakdown.module.scss'
import { formatPrice, getDiscountDetails, getEnd } from '../../../../../_metronic/helpers/utils'
import dayjs from 'dayjs'
import { getListingPrices } from '../../../../../_metronic/helpers/utils'
import { useEffect, useState } from 'react'

export const Breakdown = ({
    type,
    hours,
    days,
    start,
    end,
    price,
    vehiclesAmount,
    isSale = false,
    fee = 30,
    weeks,
    discount,
    promoCode,
    vehicles=[],
    allVehicles=[],
    listing=[]
}) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const getStart = () => {
        return {
            day: dayjs(start).format('dddd'),
            date: dayjs(start).format('MMM DD')
        }
    }

    const getEndDate = () => {
        return {
            day: dayjs(end).format('dddd'),
            date: dayjs(end).format('MMM DD')
        }
    }

    const getAmount = () => {
        if (type == 1) {
            return {
                amount: hours,
                type: +hours === 1 ? 'hour' : 'hours'
            }
        } else if (type == 2) {
            return {
                amount: days,
                type: +days == 1 ? 'day' : 'days'
            }
        } else if (type == 3) {
            return {
                amount: weeks,
                type: 'week'
            }
        } else if (type == 4) {
            return {
                amount: 1,
                type: 'month'
            }
        }
    }


    // useEffect(() => {
    //     let tempData = [] 
    //     vehicles.map((vehicle) => {
    //         const vehicleType = allVehicles.find((el) => el.id == vehicle)?.vehicle_type
    //         const pricey = getListingPrices(listing, vehicleType).find((el) => (el.plan_duration == type))?.price
    //         return {
    //             price: pricey,
    //             vehicle_id: vehicle,
    //         }
    //     })

    //     console.log

    //     function countPrices(data) {
    //         let countMap = {};
        
    //         // Iterate over each item in the JSON data
    //         Object.keys(data).forEach(key => {
    //             const price = data[key].price;
        
    //             // Check if price already exists in the countMap
    //             if (countMap[price]) {
    //                 countMap[price] += 1; // Increment count if price exists
    //             } else {
    //                 countMap[price] = 1; // Initialize count if price does not exist
    //             }
    //         });
        
    //         return countMap;
    //     }
        
    //     // Example usage
    //     const priceCounts = countPrices(tempData);
    //     console.log(priceCounts);
        
  
    // },[vehicles])

useEffect(() => {
    let totalPrice = 0
    vehicles.map((vehicle) => {
        const vehicleType = allVehicles.find((el) => el.id == vehicle)?.vehicle_type
        const pricey = getListingPrices(listing, vehicleType).find((el) => (el.plan_duration == type))?.price
        totalPrice += +pricey
    })

    setTotalPrice(totalPrice)
},[vehicles, allVehicles])


const typesOfVechiles = {
    1: 'Truck',
    2: 'Trailer',
    3: 'Truck & Trailer',
}


const getVehicleNumber = (allVehicles=[], selectedVehicle) => {
    const vehicleNumber = allVehicles.find((el) => (el?.id || el?.vehicle_id) == selectedVehicle)?.vehicle_type
    if(vehicleNumber){
        switch (vehicleNumber) {
            case 1:
                return allVehicles.find((el) => (el?.id || el?.vehicle_id) == selectedVehicle)?.truck_number
            case 2:
                return allVehicles.find((el) => (el?.id || el?.vehicle_id) == selectedVehicle)?.trailer_number
            case 3:
                return `${allVehicles.find((el) => (el?.id || el?.vehicle_id) == selectedVehicle)?.truck_number}/${allVehicles.find((el) => el.id == selectedVehicle)?.trailer_number}`
            default:
                return allVehicles.find((el) => (el?.id || el?.vehicle_id) == selectedVehicle)?.truck_number
        }
    }
}


    return (
        <div className={css.breakdown}>
            <div className={css.dates}>
                <div>
                    <div className={css.label}>Booking start</div>
                    <div className={css.title}>{getStart().day}</div>
                    <div className={css.title}>{getStart().date}</div>
                </div>
                <div className={css.right}>
                    <div className={css.label}>Booking end</div>
                    <div className={css.title}>{end ? getEndDate().day : getEnd(type, start, hours, days, weeks).day}</div>
                    <div className={css.title}>{end ? getEndDate().date : getEnd(type, start, hours, days, weeks).date}</div>
                </div>
            </div>
            {
            vehicles && vehicles.map((item) => {
                const vehicleType = allVehicles.find((el) => (el?.id || el?.vehicle_id) == item)?.vehicle_type
                const pricey = getListingPrices(listing, vehicleType).find((el) => (el.plan_duration == type))?.price
                return (
                    <div className={css.calculation}>
                    <div>
                        {formatPrice(pricey)} x {getAmount().amount} {getAmount().type} x {vehiclesAmount} {typesOfVechiles[vehicleType]} ({getVehicleNumber(allVehicles, item)})
                    </div>
                    <div>{formatPrice(pricey * getAmount().amount * vehiclesAmount)}</div>
                </div>
                )
            })
            }
          {(promoCode && discount?.discounted_amount) && (
            <div className={css.promo}>
                <div>
                    Discount ({promoCode})-({getDiscountDetails(discount, type)})
                    {discount?.discounted_amount == 10000 ? '(Up to $100)' : ''}
                </div>
                <div>${(discount?.discounted_amount/100)?.toFixed(2)}</div>
            </div>
          )}
            {!isSale && (
                <div className={css.total}>
                    <div>Total price</div>
                    <div className={css.title}>${(((totalPrice * getAmount().amount * 1)/100) - (discount?.discounted_amount ? (discount?.discounted_amount/100).toFixed(2) : 0))?.toFixed(2)}</div>
                </div>
            )}
            {isSale && (
                <>
                    <div className={css.subtotal}>
                        <div>Subtotal</div>
                        <div>{formatPrice(price * getAmount().amount * vehiclesAmount)}</div>
                    </div>
                    <div className={css.fee}>
                        <div>TruckParkingClub fee *</div>
                        <div>-{formatPrice(price * getAmount().amount * vehiclesAmount * fee)}</div>
                    </div>
                    <div className={css.total}>
                        <div>You'll make</div>
                        <div className={css.title}>
                            {formatPrice((price * getAmount().amount * vehiclesAmount) - (price * getAmount().amount * vehiclesAmount * fee))}
                        </div>
                    </div>
                    <div className={css.note}>* The fee helps us run the platform and provide the best possible service to you!</div>
                </>
            )}
        </div>
    )
}
