import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { formatPrice } from "../../../../_metronic/helpers/utils";
import { Pagination } from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";

const headers = [
  { title: 'Date', width: '180px' },
  { title: 'Reservation Number', width: '170px' },
  { title: 'Listing Name', width: '150px' },
  { title: 'Owner Name', width: '150px' },
  { title: 'Customer Name', width: '150px' },
  { title: 'Arrearage', width: '150px' },
  { title: 'Penalty', width: '120px' },
  { title: 'Arrearages Total', width: '150px' },
  { title: 'Owner\'s Share', width: '150px' },
  { title: 'TPC Share', width: '150px' },
  { title: 'Request Status', width: '240px' },
  { title: 'Created By', width: '150px' },
  { title: 'Proccessed By', width: '150px' },
  { title: 'Dates Covered/Notes', width: '200px' },
  { title: 'New Charge ID', width: '150px' },
]

const arrearStatus = (status) => {
  switch (status) {
    case 0:
      return {
        text:'Pending',
        color:'#ffa459'
      }
    case 1:
      return {
        text:'Paid',
        color:'#2ecc71'
      }
    case 2:
      return {
        text:'Failed',
        color:'#ed2626'
      }
    case 3:
      return {
        text:'Declined',
        color:'#ed2626'
  } 
    case 4:
      return {
        text:'Processing',
        color:'#ffa459'
  }
}
}


export const ArrearsTable = ({ arrears, total, isLoading, setLoading, setTrigger, page, setPage, pageSize, setPageSize }) => {

  const handleApprove = (id) => {
    setLoading(true)
    axios.patch(`${process.env.REACT_APP_API_URL}/arrearages/${id}`)
      .then(res => {
        setTimeout(() => {
          toast.success('Arrear approval is being processed');
          setTrigger(prev => !prev) 
        }, 4000)
      })
      .catch(err => {
        setLoading(false)
        if(err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        }else{
          toast.error('Could not approve arrear')
        }
      })
  }
  
  const handleDecline = (id) => {
    setLoading(true)
    axios.delete(`${process.env.REACT_APP_API_URL}/arrearages/${id}`)
      .then(res => {
        setTimeout(() => {
          toast.success('Arrear was declined successfully');
          setTrigger(prev => !prev)
        },4000)
      })
      .catch(err => {
        setLoading(false)
        if(err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        }else{
          toast.error('Could not decline arrear')
        }
      })
  }

  return (
    <KTCardBody className='py-4'>
      <h6 style={{color:'#7e8299', textAlign:'right'}}>Total: {total} </h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
                style={{ minWidth: column.width }}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {arrears?.length > 0 && (
            arrears.map(row => (
              <tr key={row.arrear_id}>
                <td style={{ minWidth: headers[0].width }}>{dayjs(row.createdAt).format('YYYY-MM-DD HH:mm A')}</td>
                <td style={{ minWidth: headers[1].width }}>{row.reservation_code || '-'}</td>
                <td style={{ minWidth: headers[2].width }}>
                  <Link to={`/listings/${row.listing_id}`}>{row.listing_name}</Link>
                </td>
                <td style={{ minWidth: headers[3].width }}>
                  <Link to={`/users/${row.owner_uid}`}>{row.owner_name}</Link>
                </td>
                <td style={{ minWidth: headers[4].width }}>
                  <Link to={`/users/${row.customer_uid}`}>{row.customer_name}</Link>
                </td>
                <td style={{ minWidth: headers[5].width }}>{formatPrice(row.original_amount)}</td>
                <td style={{ minWidth: headers[8].width }}>{formatPrice(row.penalty) || '-'}</td>
                <td style={{ minWidth: headers[6].width }}>{formatPrice(row.original_amount + row.penalty)}</td>
                <td style={{ minWidth: headers[7].width }}>{formatPrice(row.owner_share)}</td>
                <td style={{ minWidth: headers[9].width }}>{formatPrice(row.original_amount + row.penalty - row.owner_share)}</td>
                <td style={{ minWidth: headers[11].width, color: arrearStatus(row.status)?.color}}>
                {row.status === 0 ? (
                      <div className="d-flex gap-2">
                         <button
                          className="btn btn-primary btn-sm"
                          disabled={isLoading}
                          onClick={() => handleApprove(row.arrear_id)}
                        >
                          Approve
                        </button>
                         <button
                          className="btn btn-danger btn-sm"
                          disabled={isLoading}
                          style={{
                            backgroundColor: '#ed2626',
                          }}
                          onClick={() => handleDecline(row.arrear_id)}
                        >
                          Decline
                        </button>
                      </div>
                    ) :
                    (
                    <>{arrearStatus(row.status)?.text}</>
                  )}
                </td>
                <td style={{ minWidth: headers[11].width }}>{row.created_by_name || '-'}</td>
                <td style={{ minWidth: headers[11].width }}>{row.processed_by_name || '-'}</td>
                <td style={{ minWidth: headers[11].width }}>{row.notes || '-'}</td>
                <td style={{ minWidth: headers[13].width }}>
                  <a href={`https://dashboard.stripe.com/payments/${row.charge_id}`} target='_blank'>
                    {row.charge_id}
                  </a>
                </td>
              </tr>
            ))
          )}
          </tbody>
        </table>
        {arrears && !arrears.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
