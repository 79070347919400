import { useEffect, useState } from "react";
import { useDebounce, KTIcon } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import "./style.css";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import opacity from "react-element-popper/animations/opacity";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { useAuth } from "../../auth";

const AddNewPromoModalForm = ({
  setOpenPromoModal,
  fetchPromoCode,
  selectedPromo,
  setSelectedPromo,
  tab
}) => {
  const { currentUser, haveAccess } = useAuth();
  const [promoCode, setPromoCode] = useState("");
  const [promoType, setPromoType] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [limitPerUser, setLimitPerUser] = useState("");
  const [discount, setDiscount] = useState();
  const [isFfa, setFfa] = useState(false);
  const [listingId, setListingId] = useState("");
  const [listingIds, setListingIds] = useState([]);
  const [listings, setListings] = useState([]);
  const [isRenew, setRenew] = useState(false);
  const [duration, setDuration] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [cycle, setCycle] = useState("");
  const [overallLimit, setOverallLimit] = useState("");
  const [status, setStatus] = useState();
  const [requesterName, setRequesterName] = useState("");
  const [note, setNote] = useState("");
  const [search, setSearch] = useState('');
  const [isManualFormVisible, setIsManualFormVisible] = useState(true);

  const cancel = () => {
    setPromoCode("");
    setRequesterName("");
    setDiscount();
    setExpiryDate("");
    setOpenPromoModal(false);
    setSelectedPromo(null);
    setNote("");
    setListingIds([]);
  };

  useEffect(() => {
    setPromoCode("");
    setRequesterName("");
    setDiscount();
    setExpiryDate("");
    setNote("");
  }, []);

  useEffect(() => {
    if (selectedPromo && selectedPromo.id) {
      setPromoCode(selectedPromo.code);
      setDiscount(selectedPromo.discount);
      setStartDate(moment.utc(selectedPromo.start_date).toString());
      setExpiryDate(moment.utc(selectedPromo.expiry_date).toString());
      setStatus(tab);
      setLimitPerUser(selectedPromo?.limit_user)
      setOverallLimit(selectedPromo?.limit_overall)
      setNote(selectedPromo?.notes)
    }
  }, [selectedPromo]);


  const fetchListing = () => {
    debouncedSearchTerm &&
      axios.get(`${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&name=${debouncedSearchTerm}`)
        .then((res) => {
          setListings(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const debouncedSearchTerm = useDebounce(search.name, 250);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      fetchListing();
    }
  }, [debouncedSearchTerm]);



  const length = [4, 6, 8, 10, 12];

  function randomDigitCode() {
    return Math.floor(Math.random() * length.length);
  }

  function generatePromoCode(length) {
    var result = "";
    var characters =
      "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setPromoCode(result);
  }

  const addPromoCode = (e) => {
    e.preventDefault();
    if (promoCode.length < 4 || promoCode.length > 12) {
      toast.success("Please Enter Code with more than 4 and less than 12 Characters");
      return;
    } else {
      if (isFfa) {
        axios.post(`${process.env.REACT_APP_API_URL}/promo/ffa`, {
          code: promoCode,
          promo_type: promoType !== 5 ? promoType : '1,2,3,4',
          duration,
          start_date: startDate.format('YYYY-MM-DD'),
          ...(isRenew ? { end_date: expiryDate.format("YYYY-MM-DD") } : { expiry_date: expiryDate.format("YYYY-MM-DD") }),
          limit_overall: overallLimit ? overallLimit : null,
          limit_user: limitPerUser ? +limitPerUser : null,
          listing_ids: listingIds.map((item) => item.id),
          is_cyclic: isRenew,
          ...(isRenew && { cycle_duration: cycle }),
          requester_name: requesterName ? requesterName : null,
          notes: note ? note : null
        })
          .then(() => {
            toast.success("New Promo Code Added");
            cancel();
            fetchPromoCode();
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || "Technical Error, Please Try Again");
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_API_URL}/promo`, {
            code: promoCode,
            start_date: startDate.format('YYYY-MM-DD'),
            expiry_date: expiryDate.format("YYYY-MM-DD"),
            discount: discountType == 1 ? +discount * 100 : discountType == 2 ? +discount : 0,
            discount_type: discountType,
            duration: ((promoType == 1 || promoType == 2) && discountType == 3) ? duration : "",
            promo_type: promoType !== 5 ? promoType : '1,2,3,4',
            listing_ids: listingIds.map((item) => item.id),
            limit_user: limitPerUser ? +limitPerUser : null,
            limit_overall: overallLimit ? overallLimit : null,
            requester_name: requesterName ? requesterName : null,
            notes: note ? note : null
          })
          .then((res) => {
            toast.success("New Promo Code Added");
            cancel();
            fetchPromoCode();
          })
          .catch((err) => {
            if (err && err.response && err.response?.data && err.response?.data?.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Technical Error, Please Try Again");
            }
          });
      }
    }
  };

  const EditPromoCode = (e) => {
    e.preventDefault();
    axios
      .patch(`${process.env.REACT_APP_API_URL}/promo/${selectedPromo.id}`, {
        is_active: status == "active",
        ...(dayjs(expiryDate).toISOString() !== selectedPromo.expiry_date && {expiry_date: dayjs(expiryDate).format("YYYY-MM-DD")}),
        limit_overall: overallLimit ? overallLimit : null,
        limit_user: limitPerUser ? +limitPerUser : null,
        notes: note ? note : null
      })
      .then((res) => {
        toast.success(`Promo Code ${selectedPromo.code} Updated`);
        cancel();
        fetchPromoCode();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const isDisabled = () => {
    let result = false

    if (selectedPromo && selectedPromo?.id) {
      if (!expiryDate) {
        result = true
      }
    } else {
      if (!promoCode || !discountType || !startDate || !expiryDate) {
        result = true
      }

      if ((discountType == 1 || discountType == 2) && !discount) {
        result = true
      }

      if (discountType == 3 && !duration) {
        result = true
      }

      if (isFfa && !listingIds.length) {
        result = true
      }

      if (isFfa && isRenew && !cycle) {
        result = true
      }

      if (promoCode && isFfa && listingIds.length && duration && isRenew && startDate && cycle && (expiryDate || !expiryDate)) {
        result = false
      }
    }

    return result
  }


  return (
    <>
      {/* begin::Scroll */}
      <form className="form" id="kt_modal_add_user_form" autoComplete="off"
        onSubmit={selectedPromo?.id ? EditPromoCode : addPromoCode} method="post" action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {selectedPromo && selectedPromo?.id && (
            <div className="fv-row mb-3">
              <label className="required fw-bold fs-6 mb-2">Set Status</label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="active">Set Active</option>
                <option value="inactive">Set Inactive</option>
              </select>
            </div>
          )}

          {!selectedPromo && !selectedPromo?.id && <div className="fv-row mb-3">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Requester Name</label>
            {/* end::Label */}
            {/* begin::Input */}
            <input
              placeholder="Enter Requester Name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
              )}
              type="text"
              name="requestor"
              value={requesterName}
              onChange={(e) => {
                setRequesterName(e.target.value);
              }}
            />
            {/* end::Input */}
          </div>
          }
          {!selectedPromo && !selectedPromo?.id && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Set Code (Maximum upto 12 characters)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Enter Code"
                  type="text"
                  name="code"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  value={promoCode || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const truncatedValue = inputValue.substring(0, 16); // Limit to maximum 16 characters
                    const finalValue = truncatedValue.padEnd(4, "");
                    setPromoCode(finalValue);
                  }}
                  autoComplete="off"
                />
                <div
                  className="generate-random cursor-pointer"
                  onClick={() => {
                    randomDigitCode();
                    generatePromoCode(length[randomDigitCode()]);
                  }}
                >
                  Generate Random
                </div>
              </div>
              {/* end::Input */}
            </div>
          )}
          {/* end::Input group */}

          {!selectedPromo && !selectedPromo?.id && (
            <div className="fv-row mb-3">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">Promo Type</label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={promoType}
                onChange={(e) => {
                  setPromoType(+e.target.value);
                  setDiscountType("")
                }}
              >
                <option value="" disabled selected>
                  Select Promo Type
                </option>
                <option value="5">
                  For All
                </option>
                <option value="1">
                  Hourly
                </option>
                <option value="2">
                  Daily
                </option>
                <option value="3">
                  Weekly
                </option>
                <option value="4">
                  Monthly
                </option>
              </select>
            </div>
          )}

          {!selectedPromo && !selectedPromo?.id && promoType && (
            <div className="fv-row mb-3">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">Discount Type</label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={discountType}
                onChange={(e) => {
                  setDiscountType(+e.target.value)
                  setDiscount("")
                  setFfa(false)
                }}
              >
                <option value="" disabled selected>
                  Select Discount Type
                </option>
                <option value="1">
                  Discount By Amount
                </option>
                <option value="2">
                  Discount By Percent
                </option>
                {(promoType == 1 || promoType == 2) && (
                  <option value="3">
                    X Amount of {promoType == 2 ? "Days" : "Hours"} OFF
                  </option>
                )}
              </select>
            </div>
          )}

          {(!selectedPromo && !selectedPromo?.id && ((promoType == 1 || promoType == 2) && discountType == 3)) && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Set Number of {promoType == 1 ? "Hours" : "Days"}</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder="Enter Amount"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0"
                )}
                type="text"
                onWheel={(e) => e.target.blur()}
                name="durationx"
                value={duration}
                min={1}
                onChange={(e) => {
                  // const re = /^\d{0,3}(\.\d{0,2})?$/
                  const re = /^[0-9]*$/;
                  if (!re.test(e.target.value)) {
                    setDuration("");
                  } else {
                    if (+e.target.value >= +1 && +e.target.value <= 100 || e.target.value === "") {
                      setDuration(e.target.value);
                    }
                  }
                }}
              />
              {/* end::Input */}
            </div>
          )}

          {/* begin::Input group */}
          {!selectedPromo && !selectedPromo?.id && discountType && discountType == 2 && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Set Discount Percent (Upto 100%, max discount $500)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder="Enter Discount"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0"
                )}
                type="number"
                onWheel={(e) => e.target.blur()}
                name="duration"
                value={discount}
                min={1}
                onChange={(e) => {
                  const re = /^[0-9]*$/;
                  if (!re.test(e.target.value)) {
                    setDiscount("");
                  } else {
                    if (+e.target.value >= +1 && +e.target.value <= 100 || e.target.value === "") {
                      setDiscount(e.target.value);
                    }
                  }
                }}
              />
              {/* end::Input */}
            </div>
          )}

          {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 1) && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Set Discount Amount (Upto $500)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder="Enter Discount"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0"
                )}
                type="text"
                onWheel={(e) => e.target.blur()}
                name="amount"
                value={discount}
                min={1}
                onChange={(e) => {
                  // const re = /^\d{0,3}(\.\d{0,2})?$/
                  const re = /^[0-9]*$/;
                  if (!re.test(e.target.value)) {
                    setDiscount("");
                  } else {
                    if (+e.target.value >= +1 && +e.target.value <= 500 || e.target.value === "") {
                      setDiscount(e.target.value);
                    }
                  }
                }}
              />
              {/* end::Input */}
            </div>
          )}
        {(!selectedPromo && !selectedPromo?.id && discountType && !isFfa) &&
         <>
          {(listingIds.length > 0) && (
            <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Selected Listings</label>
              {listingIds.map((el, i) => (
                <div
                  key={i}
                  className="selected col-12"
                  style={{ position: "relative", marginBottom: "5px" }}
                >
                  <input
                    placeholder="Last name"
                    type="text"
                    name="name"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    disabled
                    value={el.name}
                    autoComplete="off"
                  />
                  <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() => {
                      if(listingIds.length === 1) {
                        setIsManualFormVisible(true)
                      }else{
                        setIsManualFormVisible(false)
                      }
                      setListingIds((prev) =>
                        prev.filter((elem) => elem.id !== el.id)
                      )
                    }}
                  >
                    <KTIcon iconName="cross" />
                  </button>
                </div>
              ))}
            </div>
          )}
          {isManualFormVisible && (
            <div className="manualForm fv-row mb-3">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Select Listing
              </label>
              <span className="mx-2" style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
              (optional) {search.id ? "Listing selected" : "Listing Not selected"}
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Enter Listing Name"
                  value={search.name}
                  onChange={(e) => setSearch({
                    id: "",
                    name: e.target.value
                  })}
                />
                {search.name && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setIsManualFormVisible(true);
                    setSearch({
                      id: "",
                      name: ""
                    });
                    setListings([]);
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(listings && listings.length > 0 && !search.id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {listings.map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        if (listingIds.find((el) => el.id == item.listing_id)) {
                          toast("Listing already added", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark"
                          });
                        } else {
                          setListingIds([...listingIds, {
                            id: item.listing_id,
                            name: item.title
                          }]);
                          setListings([]);
                          setSearch({
                            id: "",
                            name: ""
                          });
                          setIsManualFormVisible(false);
                        }
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {(!isManualFormVisible) && (
            <button
              onClick={() => setIsManualFormVisible(true)}
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
              className="btn col-12 mb-5"
            >
              Assign Listing
            </button>
          )}
          </>
          }
          {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 3) && (haveAccess(currentUser, 'promo_ffa_create')) && (
            <div className="input-search mt-2 mb-5 form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id='ffa'
                checked={isFfa}
                onChange={() => {
                  setFfa(prev => !prev)
                  setListingIds([])
                  setIsManualFormVisible(true);
                    setSearch({
                      id: "",
                      name: ""
                    });
                    setListings([]);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="qr"
                style={{ fontWeight: "700", color: "#3f4254" }}
              >
                Is Free For All
              </label>
            </div>
          )}

          {/* {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 3 && isFfa) && (
            <div className="fv-row mb-3">
              <label className="required fw-bold fs-6 mb-2">Enter Listing Id</label>
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Enter Listing Id"
                  type="text"
                  name="listingId"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  value={listingId || ""}
                  onChange={(e) => {
                    const re = /^[0-9]*$/
                    if (!re.test(e.target.value)) {
                      return;
                    } else {
                      setListingId(e.target.value);
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
          )} */}
       {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 3 && isFfa) &&
         <>
          {(listingIds.length > 0) && (
            <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Selected Listings</label>
              {listingIds.map((el, i) => (
                <div
                  key={i}
                  className="selected col-12"
                  style={{ position: "relative", marginBottom: "5px" }}
                >
                  <input
                    placeholder="Last name"
                    type="text"
                    name="name"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    disabled
                    value={el.name}
                    autoComplete="off"
                  />
                  <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() => {
                      if(listingIds.length === 1) {
                        setIsManualFormVisible(true)
                      }else{
                        setIsManualFormVisible(false)
                      }
                      setListingIds((prev) =>
                        prev.filter((elem) => elem.id !== el.id)
                      )
                    }}
                  >
                    <KTIcon iconName="cross" />
                  </button>
                </div>
              ))}
            </div>
          )}
          {isManualFormVisible && (
            <div className="manualForm fv-row mb-3">
              <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                Select Listing
              </label>
              <span className="mx-2" style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
                 {search.id ? "Listing selected" : "Listing Not selected"}
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Enter Listing Name"
                  value={search.name}
                  onChange={(e) => setSearch({
                    id: "",
                    name: e.target.value
                  })}
                />
                {search.name && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setIsManualFormVisible(true);
                    setSearch({
                      id: "",
                      name: ""
                    });
                    setListings([]);
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(listings && listings.length > 0 && !search.id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {listings.map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        if (listingIds.find((el) => el.id == item.listing_id)) {
                          toast("Listing already added", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark"
                          });
                        } else {
                          setListingIds([...listingIds, {
                            id: item.listing_id,
                            name: item.title
                          }]);
                          setListings([]);
                          setSearch({
                            id: "",
                            name: ""
                          });
                          setIsManualFormVisible(false);
                        }
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {(!isManualFormVisible) && (
            <button
              onClick={() => setIsManualFormVisible(true)}
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
              className="btn col-12 mb-5"
            >
              Assign Listing
            </button>
          )}
          </>
          }
          {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 3 && isFfa) && (
            <div className="input-search mt-2 mb-5 form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id='renew'
                checked={isRenew}
                onChange={() => {
                  setRenew(prev => !prev)
                  setExpiryDate(null)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="qr"
                style={{ fontWeight: "700", color: "#3f4254" }}
              >
                Does It Renew Itself?
              </label>
            </div>
          )}

          {(!selectedPromo && !selectedPromo?.id && discountType && discountType == 3 && isFfa && isRenew) && (
            <div className="fv-row mb-3">
              <label className="required fw-bold fs-6 mb-2">Days in Cycle</label>
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Enter Days"
                  type="text"
                  name="cycle"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  value={cycle || ""}
                  onChange={(e) => {
                    const re = /^[0-9]*$/
                    if (!re.test(e.target.value)) {
                      return;
                    } else {
                      setCycle(e.target.value)
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
          )}

          {(discountType || (!selectedPromo && !selectedPromo?.id)) && (
            <div className="fv-row mb-3">
              <label className="required fw-bold fs-6 mb-2">Starting Date</label>
              <DatePicker
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  padding: "0.775rem 1rem",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  lineHeight: "1.5",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #f9f9f9",
                  color: "#5e6278",
                  transition: "color 0.2s ease",
                  height: "auto"
                }}
                containerStyle={{
                  width: "100%"
                }}
                className="rmdp-mobile custom-calendar"
                arrow={false}
                value={startDate}
                onChange={setStartDate}
                format="dddd MMM DD,YYYY"
                fixMainPosition={true}
                // minDate={new Date()}
                minDate={moment().utc().toDate()}
                maxDate={expiryDate ? dayjs(expiryDate?.format("YYYY-MM-DD")).toDate() : null}
                animations={[opacity()]}
                placeholder="Select Starting Date"
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                scrollSensitive={false}
                hideOnScroll={false}
              />
            </div>
          )}

          {(discountType || (selectedPromo && selectedPromo?.id && !selectedPromo.is_cyclic)) && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className={`${!isRenew && 'required'} fw-bold fs-6 mb-2`}>{isRenew ? 'End Date' : 'Expiration Date'}</label>
              {/* end::Label */}
              {/* begin::Input */}
              <DatePicker
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  padding: "0.775rem 1rem",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  lineHeight: "1.5",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #f9f9f9",
                  color: "#5e6278",
                  transition: "color 0.2s ease",
                  height: "auto"
                }}
                containerStyle={{
                  width: "100%"
                }}
                className="rmdp-mobile custom-calendar"
                arrow={false}
                value={expiryDate}
                onChange={setExpiryDate}
                format="dddd MMM DD,YYYY"
                fixMainPosition={true}
                minDate={selectedPromo?.id ? dayjs().diff(startDate, "day") < 0 ? dayjs(startDate).toDate() : moment().utc().toDate() : dayjs(startDate?.format("YYYY-MM-DD"))
                  .toDate()}
                // minDate={moment().utc().toDate()}
                animations={[opacity()]}
                placeholder={isRenew ? 'Select End Date' : "Select Expiration Date"}
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                scrollSensitive={false}
                hideOnScroll={false}
              />
              {/* end::Input */}
            </div>
          )}

          {(discountType || (selectedPromo && selectedPromo?.id)) && (
            <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Total Usage Per User (Optional)</label>
              <div style={{ position: "relative" }}>
                <input
                  placeholder="Enter Limit"
                  type="text"
                  name="limitUser"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  value={limitPerUser || ""}
                  onChange={(e) => {
                    const re = /^[0-9]*$/
                    if (!re.test(e.target.value)) {
                      return;
                    } else {
                      setLimitPerUser(e.target.value)
                    }
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
          )}

          {(discountType || (selectedPromo && selectedPromo?.id)) && (
            <div className="fv-row mb-3">
              {/* begin::Label */}
              <label className="fw-bold fs-6 mb-2">Total Uses Allowed (Optional)</label>
              {/* end::Label */}
              {/* begin::Input */}
              <input
                placeholder="Set Total Uses Allowed"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0"
                )}
                type="number"
                onWheel={(e) => e.target.blur()}
                name="limit"
                value={overallLimit}
                min={1}
                onChange={(e) => {
                  const re = /^[0-9]*$/;
                  if (!re.test(e.target.value)) {
                    setOverallLimit("");
                  } else {
                    setOverallLimit(e.target.value);
                  }
                }}
              />
              {/* end::Input */}
            </div>
          )}
          {(discountType || (selectedPromo && selectedPromo?.id)) && (
           <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Note (Optional)</label>
              <textarea
                className="form-control form-control-solid mb-3 mb-lg-0 resize-none h-100px"
                name="note"
                placeholder="Enter Note..."
                value={note}
                onChange={(e) => setNote(e.target.value.substring(0, 250))}
                maxLength={250}
              ></textarea>
            </div>
            )}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          {!selectedPromo && !selectedPromo?.id && (
            <button
              type="submit"
              className="btn"
              data-kt-users-modal-action="submit"
              style={{ background: "#ffa459", color: "#fff" }}
              disabled={isDisabled()}
            >
              <span className="indicator-label">Submit</span>
            </button>
          )}

          {selectedPromo && selectedPromo?.id && (
            <button
              type="submit"
              className="btn"
              data-kt-users-modal-action="submit"
              style={{ background: "#ffa459", color: "#fff" }}
              disabled={isDisabled()}
            >
              <span className="indicator-label">Save Changes</span>
            </button>
          )}
        </div>
        {/* end::Actions */}

      </form >
    </>
  );
};

export { AddNewPromoModalForm };
