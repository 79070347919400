import { NotifierEmailModalForm } from "./NotifierEmailModalForm"

const NotifierEmailModalFormWrapper = ({
  selectedReport,
  setSelectedReport,
  setOpenNotifierEmailModal
}) => {

  if (selectedReport) {
    return (
      <NotifierEmailModalForm
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        setOpenNotifierEmailModal={setOpenNotifierEmailModal}
      />
    );
  }
  return null;
};

export { NotifierEmailModalFormWrapper };
